import { FC } from 'react'

import { Info } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import { CarIconGreen } from '@/root/ui/shared/icons/CarIconGreen'
import { CarIconGray } from '@/root/ui/shared/icons/CarIconGray'
import { DriverIconGreen } from '@/root/ui/shared/icons/DriverGreen'
import { DriverIconGray } from '@/root/ui/shared/icons/DriverIconGray'

interface InfoSectionTitleProps extends Info {
  isSectionFilled: boolean
}
export const InfoSectionTitle: FC<InfoSectionTitleProps> = ({ type, data, isSectionFilled }) => {
  const { getTranslation } = useGetTranslations()

  const titleText = {
    car: {
      title: getTranslation('ticketVehicleRegNumber'),
      icon: isSectionFilled ? <CarIconGreen /> : <CarIconGray />,
    },
    driver: {
      title: getTranslation('driver'),
      icon: isSectionFilled ? <DriverIconGreen /> : <DriverIconGray />,
    },
  }
  return (
    <span className="flex items-center gap-[10px]">
      {titleText[type].icon}
      <p>
        {titleText[type].title} <strong>{data}</strong>
      </p>
    </span>
  )
}
