import { LockerIcon } from '@/root/ui/shared/icons/LockerIcon'
import { FC } from 'react'

interface NonEditableFieldProps {
  title: string
  value: string
}

export const NonEditableField: FC<NonEditableFieldProps> = ({ title, value }) => (
  <div className="flex max-w-screen-xs justify-between">
    <span className="flex flex-1 gap-3 text-[16px] text-neutral-medium-lighter">
      <LockerIcon />
      <p>{title}</p>
    </span>
    <p className="flex-1 font-bold text-neutral-medium-light ">{value}</p>
  </div>
)
