export const firstNameValidationPattern = new RegExp(/^[A-Za-z\s'-]+$/)
export const lastNameValidationPattern = new RegExp(/^[A-Za-z\s'-]+$/)
export const emailValidationPattern = /^[\w.-]+@[\w.-]+\.\w+$/i
export const phoneValidationPattern = /^\d{8,15}$/
export const legalNameValidationPattern = new RegExp(/^[\s\S]{0,160}$/)
export const rucValidationPattern = new RegExp(/^[A-Z0-9\s-]+$/)
export const vatNumValidationPattern = new RegExp(/^.{4,30}$/)
export const emailNumValidationPattern = new RegExp(/^[\w.-]+@[\w.-]+\.\w+$/i)
export const zipValidationPattern = /^[A-Za-z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=`|"' ]{0,32}$/

export const translations = {
  confirmPageCompanyName: {
    en: 'Company Name',
    es: 'Nombre de la empresa',
  },
  confirmPageCountry: {
    en: 'Country',
    es: 'País',
  },
  confirmPageVatNum: {
    en: 'VatNum',
    es: 'Número de IVA',
  },
  confirmPageRuc: {
    en: 'RUC',
    es: 'RUC',
  },
  confirmPageCompanyPhoneNumber: {
    en: 'Phone Number',
    es: 'Número de teléfono',
  },
  confirmPageCompanyEmail: {
    en: 'E-mail',
    es: 'E-mail',
  },
  confirmPageZipCode: {
    en: 'Zip Code',
    es: 'Código postal',
  },
  confirmPageFirstName: {
    en: 'First name',
    es: 'Nombre',
  },
  confirmPageSurname: {
    en: 'Surname',
    es: 'Nombre',
  },
  confirmPagePersonEmail: {
    en: 'E-mail',
    es: 'E-mail',
  },
}

export const saveInLocalStorage = function <T>(key: string, value: T) {
  const processedValue = typeof value === 'object' ? JSON.stringify(value) : (value as string)
  localStorage.setItem(key, processedValue)
}

export const getLocalStorageValueFor = (key: string) => {
  return localStorage.getItem(key)
}
