import { FC } from 'react'
import { DynamicButton } from '@/root/components/bluewave/DynamicButton/DynamicButton'
import { InputProps } from '@/root/shared-types'

export const TextareaWithButton: FC<InputProps> = ({ onSave, disabled, id, control: { register }, disableOnSave }) => {
  return (
    <span className="flex w-[95%] flex-col gap-3 md:max-w-screen-xs">
      <textarea
        className="h-[110px] w-full items-end rounded-l border border-neutral-normal-lighter px-2 "
        disabled={disabled}
        {...register(id)}
      />
      <DynamicButton onClick={onSave} saved={disabled} className="w-[110px] self-end" disabled={disableOnSave} />
    </span>
  )
}
