import { FC, useEffect, useState } from 'react'
import { InputWithButton } from '@/root/components/bluewave/TextInputWithButton/InputWithButton'
import { TextareaWithButton } from '@/root/components/bluewave/TextareaWithButton/TextareaWithButton'
import { FieldValues, UseFormWatch, Control, UseFormResetField } from 'react-hook-form'
import { SelectInput } from '@/root/components/bluewave/Select/SelectInput'
import { FileInput } from '@/root/components/bluewave/FileInput/FileInput'
import { InfoFieldType, Options, attributeType } from '@/root/shared-types'
import { usePostAttribute } from '@/root/shared-hooks/additional-info/usePostAttribute'
import { NonEditableField } from '@/root/components/bluewave/NonEditableField/NonEditableField'
import { EditableField } from '@/root/components/bluewave/EditableField/EditableField'
import { useEditPersonalData } from '@/root/shared-hooks/additional-info/usePostPersonalData'
import { useErrorBanner } from '@/root/shared-hooks/useErrorBanner/useErrorBanner'
import { useGetTranslations } from '@/root/shared-hooks'
import { DoneIcon } from '@/root/ui/shared/icons/DoneIcon'
import { WarningIconRed } from '@/root/ui/shared/icons/WarningIconRed'

interface InfoSectionInputFieldProps {
  title: string
  id: string
  editMode: boolean
  watch: UseFormWatch<FieldValues>
  isDone: boolean
  reservationId: number
  seqN: number
  attributeType: attributeType
  control: Control
  resetField: UseFormResetField<FieldValues>
  placeholder?: string
  type?: InfoFieldType
  options?: Options
  editable?: boolean
  value?: string
  isAttribute?: boolean
}

export const InfoSectionInputField: FC<InfoSectionInputFieldProps> = ({
  title,
  type = 'text',
  id,
  watch,
  options,
  isDone,
  reservationId,
  seqN,
  attributeType,
  placeholder,
  control,
  editable = true,
  value,
  editMode,
  isAttribute = true,
  resetField,
}) => {
  const [enableEditMode, setEnableEditMode] = useState(editMode)
  const [isLoading, setIsLoading] = useState(false)
  const fieldValue = watch(id)
  const { getTranslation } = useGetTranslations()

  const { saveAttribute } = usePostAttribute({
    reservationId,
    seqN,
    attribute: id,
    value: fieldValue,
    attributeType,
    type,
  })

  const { postPersonalData } = useEditPersonalData({
    reservationId,
    seqN,
    modificationMode: id,
    value: fieldValue,
  })
  const [saved, setSaved] = useState(true)
  const { errorHandler } = useErrorBanner()

  useEffect(() => {
    if (type === 'file') {
      if (!saved && !fieldValue?.length) {
        setSaved(true)
      }
      if (saved && fieldValue?.length) {
        setSaved(false)
      }
    }
  }, [fieldValue, saved, type])

  if (!editable && value) {
    return <NonEditableField title={title} value={value} />
  }

  if (enableEditMode && editable) {
    const inputTypeDefiner = {
      text: InputWithButton,
      textarea: TextareaWithButton,
      select: SelectInput,
      file: FileInput,
      date: InputWithButton,
    }

    const Input = inputTypeDefiner[type]

    const onSave = async () => {
      const time = new Date()
      try {
        if (type === 'select' && isAttribute) {
          await saveAttribute()
          return setEnableEditMode(false)
        }
        setSaved(false)

        if (!saved) {
          if (fieldValue?.length) {
            setIsLoading(true)
            if (isAttribute) {
              await saveAttribute()
            } else {
              await postPersonalData()
            }
            setIsLoading(false)
            setSaved(true)
            setEnableEditMode(false)
            if (type === 'file') {
              resetField(id)
            }
          } else {
            setSaved(true)
          }
        }
      } catch (error) {
        if (errorHandler) {
          errorHandler({ error, time, message: getTranslation('additionalFieldsProcessError') })
        }
      }
    }

    const disableOnSave = Boolean((!fieldValue && !saved) || (value === fieldValue && !saved))

    return (
      <div className="flex flex-col">
        <span className="flex gap-2">
          {isDone ? <DoneIcon width={19} height={18} /> : <WarningIconRed />}
          <p>{title}</p>
        </span>
        <Input
          disabled={saved}
          onSave={onSave}
          id={id}
          options={options}
          placeholder={
            (type === 'file' ? fieldValue && fieldValue[0]?.name : fieldValue) || (!isLoading && placeholder)
          }
          control={control}
          disableOnSave={disableOnSave}
          isLoading={isLoading}
          type={type}
        />
      </div>
    )
  }

  return (
    <EditableField
      value={(type === 'file' ? fieldValue && fieldValue[0]?.name : fieldValue) || value || placeholder || ''}
      title={title}
      onModeToggle={() => setEnableEditMode(true)}
    />
  )
}
