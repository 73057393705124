import { ReservationItem, Vehicle } from '@/root/shared-types'
import Image from 'next/image'
import { FC } from 'react'
import { RowContainer } from '@/root/modules/bluewave/PrintedTicket/components/RowContainer/RowContainer'
import { useGetTranslations, useSetQrImgSrc } from '@/root/shared-hooks'

interface TicketItemsWithQRProps {
  items: ReservationItem[]
  reservationId: number
  sailRefId: number
  displayQR: boolean
  hideBorder?: boolean
  vehicles?: Vehicle[]
  routeToScrollOnMobile?: boolean
}

const TicketItemsWithQR: FC<TicketItemsWithQRProps> = ({
  items,
  reservationId,
  sailRefId,
  vehicles,
  displayQR,
  hideBorder,
  routeToScrollOnMobile,
}) => {
  const { getTranslation } = useGetTranslations()
  const { qrImgSrc } = useSetQrImgSrc({ sailRefId, reservationId })

  return (
    <RowContainer hideBorder={hideBorder} display="flex" routeToScrollOnMobile={routeToScrollOnMobile}>
      <div className="mt-5 flex w-full flex-col space-y-4 text-lg  md:self-start">
        {items.map(({ priceCategoryTranslation, quantity }) => {
          return (
            <div key={priceCategoryTranslation}>
              {quantity} x {priceCategoryTranslation}
            </div>
          )
        })}
      </div>

      <div className="flex w-full flex-col gap-5 pt-3 text-left md:items-end md:self-start">
        {displayQR && qrImgSrc && (
          <div className="max-w-[300px] self-center md:max-w-[140px] md:self-end">
            <Image alt="ticket qr code" src={qrImgSrc} width={500} height={500} className="ml-auto mr-2" />
          </div>
        )}

        {vehicles &&
          vehicles.map((vehicle) => (
            <p key={vehicle.licencePlate} className="text-lg">
              {getTranslation('ticketVehicleRegNumber')} <strong>{vehicle.licencePlate}</strong>
            </p>
          ))}
      </div>
    </RowContainer>
  )
}

export default TicketItemsWithQR
