import { FC } from 'react'
import { DynamicButton } from '@/root/components/bluewave/DynamicButton/DynamicButton'
import { InputProps } from '@/root/shared-types'

export const InputWithButton: FC<InputProps> = ({
  onSave,
  disabled,
  id,
  control: { register },
  disableOnSave,
  isLoading,
  type,
}) => {
  return (
    <span className="flex h-9 w-[95%] md:max-w-screen-xs">
      <input
        {...register(id)}
        type={type}
        className="flex-1 overflow-hidden rounded-l border border-neutral-normal-lighter px-2 disabled:bg-neutral-lightest"
        disabled={disabled}
      />
      <DynamicButton
        className="rounded rounded-s-none"
        onClick={onSave}
        saved={disabled}
        disabled={disableOnSave}
        isLoading={isLoading}
      />
    </span>
  )
}
