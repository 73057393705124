import { FC } from 'react'
import Image from 'next/image'
import { ImageProps } from '@/root/shared-types'

interface NotificationBodyProps {
  body: string
  image?: ImageProps
}
export const NotificationBody: FC<NotificationBodyProps> = ({ body, image }) => (
  <div className="flex gap-6 self-start px-6 pr-10 text-black">
    {image && <Image src={'/api' + image.url} alt={image.alternativeText} width={200} height={150} />}
    <div dangerouslySetInnerHTML={{ __html: body }} />
  </div>
)
