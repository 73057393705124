import React from 'react'
import RadioInput from './RadioInput'
import { TabProps } from '@/root/shared-types/companyInfoPage/company-info-types'

const Tab: React.FC<TabProps> = ({ activeTab, label, onChange, id }) => {
  const isActive = activeTab === id
  const activeClass = isActive ? 'active' : ''

  return (
    <li className={`tab ${activeClass}`}>
      <RadioInput label={label} id={id} checked={isActive} onChange={onChange} />
    </li>
  )
}

export default Tab
