import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

export const ContentContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => (
  <div
    className={cn(
      'max-w-screen-xl mx-auto bg-white my-10 pt-10 pb-20 flex flex-col items-center gap-12 min-h-[350px] shadow-md px-12 xl:px-24',
      className
    )}
  >
    {children}
  </div>
)
