import { ChevronDownIcon } from '@/root/ui/shared/icons/ChevronDownIcon'
import cn from 'classnames'
import React, { FC } from 'react'

interface ExpandToggleProps {
  expanded: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}
export const ExpandToggle: FC<ExpandToggleProps> = ({ expanded, onClick }) => (
  <div
    onClick={onClick}
    className={cn('flex cursor-pointer w-6 h-6 rounded-full justify-center hover:bg-neutral-normal-lighter', {
      'rotate-180': expanded,
    })}
  >
    <ChevronDownIcon />
  </div>
)
