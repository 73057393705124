import { FC, useEffect, useState } from 'react'
import {
  CircleWithPoint,
  DashLine,
  EmptyCircleButton,
  SolidLine,
  StepCompletedButton,
} from '@/root/modules/shared/BookingProgressBar/components'
import { bookingSteps } from '@/root/shared-consts'
import { BookingStep } from '@/root/shared-types'
import StepLabels from './StepLabelsProgressBar'

interface BookingProgressBarProps {
  maxReachedStep: BookingStep
}

const { SELECT_DATE_TIME, SELECT_TICKETS, CONFIRM } = bookingSteps

export const BookingProgressBar: FC<BookingProgressBarProps> = ({ maxReachedStep = SELECT_DATE_TIME }) => {
  const [currentStep, setCurrentStep] = useState<BookingStep>(maxReachedStep)

  useEffect(() => {
    setCurrentStep(maxReachedStep)
  }, [maxReachedStep])

  return (
    <div className="flex flex-col">
      <div className="flex w-full justify-center">
        {currentStep === SELECT_DATE_TIME && (
          <>
            <CircleWithPoint />
            <DashLine />
            <EmptyCircleButton
              onClick={() => setCurrentStep(SELECT_TICKETS)}
              disabled={maxReachedStep === SELECT_DATE_TIME}
            />
            <DashLine />
            <EmptyCircleButton onClick={() => setCurrentStep(CONFIRM)} disabled={maxReachedStep !== CONFIRM} />
          </>
        )}

        {currentStep === SELECT_TICKETS && (
          <>
            <StepCompletedButton onClick={() => setCurrentStep(SELECT_DATE_TIME)} />
            <SolidLine />
            <CircleWithPoint />
            <DashLine />
            <EmptyCircleButton onClick={() => setCurrentStep(CONFIRM)} disabled={maxReachedStep !== CONFIRM} />
          </>
        )}

        {currentStep === CONFIRM && (
          <>
            <StepCompletedButton onClick={() => setCurrentStep(CONFIRM)} />
            <SolidLine />
            <StepCompletedButton onClick={() => setCurrentStep(CONFIRM)} />
            <SolidLine />
            <CircleWithPoint />
          </>
        )}
      </div>

      <StepLabels currentStep={currentStep} />
    </div>
  )
}

export default BookingProgressBar
