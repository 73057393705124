import { FC } from 'react'
import { RowContainer } from '@/root/modules/bluewave/PrintedTicket/components/RowContainer/RowContainer'
import { BookingStatus } from '@/root/shared-types/reservation/reservation-types'
import { useGetTranslations } from '@/root/shared-hooks'

interface CustomerInfoProps {
  email: string
  phoneNumber: string
  fullName: string
  reservationId: number
  status: BookingStatus
}

export const CustomerInfo: FC<CustomerInfoProps> = ({ email, phoneNumber, fullName, reservationId, status }) => {
  const { getTranslation } = useGetTranslations()
  const isReservationPaid = status === 'OK'

  return (
    <RowContainer className="pb-2">
      <p className="text-2xl font-bold">{fullName}</p>
      <div>
        <p className="text-center text-lg md:text-left">{phoneNumber}</p>
        <p className="break-all text-lg">{email}</p>
      </div>
      <div className="flex md:justify-end">
        <p>
          {getTranslation(isReservationPaid ? 'ticketTicketNumber' : 'ticketBookingNumber')}{' '}
          <strong className="text-2xl">{reservationId}</strong>
        </p>
      </div>
    </RowContainer>
  )
}
