import { FC, useEffect, useState } from 'react'
import {
  CircleWithPoint,
  DashLine,
  EmptyCircleButton,
  SolidLine,
  StepCompletedButton,
} from '@/root/modules/shared/BookingProgressBar/components'
import { useGetTranslations } from '@/root/shared-hooks'
import cn from 'classnames'
import { bookingSteps } from '@/root/shared-consts'
import { BookingStep } from '@/root/shared-types'

interface BookingProgressBarProps {
  maxReachedStep: BookingStep
}

const { SELECT_DATE_TIME, SELECT_TICKETS, CONFIRM } = bookingSteps

export const BookingProgressBar: FC<BookingProgressBarProps> = ({ maxReachedStep = SELECT_DATE_TIME }) => {
  const { getTranslation } = useGetTranslations()
  const [currentStep, setCurrentStep] = useState<BookingStep>(maxReachedStep)

  useEffect(() => {
    setCurrentStep(maxReachedStep)
  }, [maxReachedStep])

  return (
    <div data-testid="stepper-around" className="flex flex-col">
      <div className="flex justify-center gap-1.5 sm:gap-2.5">
        {currentStep === SELECT_DATE_TIME && (
          <>
            <CircleWithPoint />
            <DashLine />
            <EmptyCircleButton
              onClick={() => setCurrentStep(SELECT_TICKETS)}
              disabled={maxReachedStep === SELECT_DATE_TIME}
            />
            <DashLine />
            <EmptyCircleButton onClick={() => setCurrentStep(CONFIRM)} disabled={maxReachedStep !== CONFIRM} />
          </>
        )}

        {currentStep === SELECT_TICKETS && (
          <>
            <StepCompletedButton onClick={() => setCurrentStep(SELECT_DATE_TIME)} />
            <SolidLine />
            <CircleWithPoint />
            <DashLine />
            <EmptyCircleButton onClick={() => setCurrentStep(CONFIRM)} disabled={maxReachedStep !== CONFIRM} />
          </>
        )}

        {currentStep === CONFIRM && (
          <>
            <StepCompletedButton onClick={() => setCurrentStep(SELECT_DATE_TIME)} />
            <SolidLine />
            <StepCompletedButton onClick={() => setCurrentStep(SELECT_TICKETS)} />
            <SolidLine />
            <CircleWithPoint />
          </>
        )}
      </div>

      <div className="-mt-6 grid w-[336px] grid-cols-3 justify-items-center gap-1 text-center text-xs sm:w-[504px]">
        <p className="text-base-darkest">{getTranslation('destinationStep')}</p>
        <p className={cn(currentStep !== SELECT_DATE_TIME ? 'text-base-darkest' : 'text-neutral-normal-lighter')}>
          {getTranslation('selectTicketStep')}
        </p>
        <p className={cn(currentStep === CONFIRM ? 'text-base-darkest' : 'text-neutral-normal-lighter')}>
          {getTranslation('selectConfirmStep')}
        </p>
      </div>
    </div>
  )
}

export default BookingProgressBar
