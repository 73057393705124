import { FC, HTMLAttributes, useRef, useEffect } from 'react'
import cn from 'classnames'

interface RowContainerProps extends HTMLAttributes<HTMLDivElement> {
  hideBorder?: boolean
  display?: 'grid' | 'flex'
  routeToScrollOnMobile?: boolean
}
export const RowContainer: FC<RowContainerProps> = ({
  children,
  hideBorder,
  className = 'items-center justify-between',
  display = 'grid',
  routeToScrollOnMobile = false,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (routeToScrollOnMobile) {
      const mql = window.matchMedia('(max-width: 600px)')
      if (mql.matches) {
        ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [routeToScrollOnMobile])
  return (
    <div
      className={cn(
        { 'border-b': Boolean(!hideBorder) },
        `flex max-[800px]:flex-col max-[800px]:space-y-3 justify-center items-center ${className}`,
        { 'md:grid-cols-[35%_40%_25%]': display === 'grid' },
        { 'md:grid': display === 'grid' },
        { 'flex justify-between': display === 'flex' }
      )}
      ref={ref}
    >
      {children}
    </div>
  )
}
