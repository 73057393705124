import { ButtonHTMLAttributes, FC } from 'react'
import { ButtonForInput } from '@/root/ui/bluewave'
import { ButtonSpinner } from '@/root/ui/bluewave'

import { useGetTranslations } from '@/root/shared-hooks'
import { EditIconWhite } from '@/root/ui/shared/icons/EditWhiteIcon'
import { DoneIconWhite } from '@/root/ui/shared/icons/DoneIconWhite'
import { UploadIcon } from '@/root/ui/shared/icons/UploadIcon'
import { FileIcon } from '@/root/ui/shared/icons/FileIcon'

interface DynamicButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  saved: boolean
  typeOfButton?: 'text' | 'file'
  isLoading?: boolean
}
export const DynamicButton: FC<DynamicButtonProps> = ({
  saved,
  onClick,
  className,
  typeOfButton = 'text',
  disabled,
  isLoading,
}) => {
  const { getTranslation } = useGetTranslations()

  if (isLoading) {
    return (
      <ButtonForInput disabled>
        <div>
          <ButtonSpinner />
          {typeOfButton === 'file' ? getTranslation('uploading') : getTranslation('saving')}
        </div>
      </ButtonForInput>
    )
  }

  const textAndIconDefiner = {
    text: saved ? (
      <>
        <EditIconWhite width={18} height={18} /> {getTranslation('actionEdit')}
      </>
    ) : (
      <>
        <DoneIconWhite width={18} height={18} />
        {getTranslation('actionSave')}
      </>
    ),
    file: saved ? (
      <>
        <UploadIcon />
        {getTranslation('actionUploadFile')}
      </>
    ) : (
      <>
        <FileIcon width={18} height={18} />
        {getTranslation('actionSelectFile')}
      </>
    ),
  }

  return (
    <ButtonForInput onClick={onClick} className={className} disabled={disabled}>
      {textAndIconDefiner[typeOfButton]}
    </ButtonForInput>
  )
}
