import React, { FC } from 'react'
import Downshift from 'downshift'
import { DEFAULT_PHONE } from './const'
import cn from 'classnames'

import {
  IntlCodeDropdownProps,
  IntlCodeInputProps,
  CodeAndTitle,
  PhoneAutocompleteProps,
} from '@/root/shared-types/companyInfoPage/company-info-types'
import { getLocalStorageValueFor, saveInLocalStorage } from './utils'
import IconFlag from './IconFlag'
import { CustomerInfoArrowDropdownIcon } from '@/root/ui/shared/icons/CustomerInfoArrowDropdownIcon'

const IntlCodeDropdown: FC<IntlCodeDropdownProps> = ({
  inputValue,
  getItemProps,
  getMenuProps,
  phones,
  selectedItem,
}) => {
  return (
    <div className="h-230 absolute top-10 z-20 w-full overflow-scroll">
      <ul
        {...getMenuProps()}
        className="border-neutral-normal-lighter max-h-44 w-full content-start overflow-scroll rounded border bg-white text-left text-lg"
      >
        {phones
          .filter((item) => !inputValue || item.title.toLowerCase().includes(inputValue.toLowerCase()))
          .map((item, index) => (
            <li
              key={item.code}
              {...getItemProps({
                index,
                item,
              })}
              className={cn('flex items-center justify-between p-2', {
                'bg-neutral-medium-lighter font-bold': selectedItem === item,
                'bg-white': selectedItem !== item,
                'hover:bg-neutral-normal-lighter': true,
              })}
            >
              <div>{item.title}</div>
              <div className="pr-2">
                {selectedItem?.code && (
                  <IconFlag alt="countryFlag" width={21} height={16} countryCode={item.code.toLowerCase()} src={''} />
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}

const IntlCodeInput: FC<IntlCodeInputProps> = ({ toggleMenu, id, getInputProps, selectedItem }) => {
  const inputProps = getInputProps()

  return (
    <div className="relative">
      <div
        {...inputProps}
        onClick={(event) => {
          if (inputProps.onClick) {
            inputProps.onClick(event as React.MouseEvent<HTMLInputElement>)
          }
          toggleMenu()
        }}
        className="border-neutral-normal-lighter focus-within:border-neutral-normal-lighter md:grid-cols-auto-fit flex justify-center rounded border-solid pr-2 align-middle"
      >
        <input
          id={id}
          className="h-10 w-1/2 grow rounded border-none text-center font-bold outline-none"
          value={selectedItem?.title}
          name={id}
          {...getInputProps()}
          required
        />
        <div className="flex items-center pr-2">
          <IconFlag alt="countryFlag" width={21} countryCode={selectedItem?.code.toLowerCase()} src={''} />
        </div>
        <span className="pointer-events-none flex items-center pr-2" suppressHydrationWarning></span>
        <button type="button">
          <CustomerInfoArrowDropdownIcon />
        </button>
      </div>
    </div>
  )
}

const PhoneAutocomplete: FC<PhoneAutocompleteProps> = ({ handleSelect, id, register, phones }) => {
  const getInitialSelectedItem = (id: string) => {
    if (typeof window !== 'undefined') {
      const savedSelectedItem = getLocalStorageValueFor(id)
      return savedSelectedItem ? JSON.parse(savedSelectedItem) : DEFAULT_PHONE
    }
    return DEFAULT_PHONE
  }

  const handleChange = (selectedItem: CodeAndTitle = DEFAULT_PHONE): void => {
    handleSelect(selectedItem)
    saveInLocalStorage(`${id}`, JSON.stringify(selectedItem))
  }
  return (
    <>
      <Downshift
        id={id}
        itemToString={(item) => (item ? item.title : '')}
        initialSelectedItem={getInitialSelectedItem(id)}
        onSelect={(selection) => handleChange(selection)}
      >
        {({ getInputProps, getItemProps, getMenuProps, isOpen, toggleMenu, inputValue, selectedItem }) => {
          return (
            <div className="min-w-107 relative mb-0">
              <div className="box border-neutral-normal-lighter focus-within:border-neutral-normal-lighter relative grid grid-cols-1 rounded border border-solid focus-within:border-solid md:grid-cols-1">
                <IntlCodeInput
                  register={register}
                  id={id}
                  selectedItem={selectedItem}
                  getInputProps={getInputProps}
                  toggleMenu={toggleMenu}
                />
                {isOpen && (
                  <IntlCodeDropdown
                    toggleMenu={toggleMenu}
                    inputValue={inputValue}
                    getItemProps={getItemProps}
                    getMenuProps={getMenuProps}
                    selectedItem={selectedItem}
                    phones={phones}
                  />
                )}
              </div>
            </div>
          )
        }}
      </Downshift>
    </>
  )
}

export default PhoneAutocomplete
