import React, { FC } from 'react'
import { getDateFromISODate } from '@/root/shared-helpers'
import { ExpandToggle } from '@/root/components/bluewave/ExpandToggle/ExpandToggle'
import cn from 'classnames'
interface NotificationTitleProps {
  title: string
  date: string
  onClick: React.MouseEventHandler<HTMLDivElement>
  expanded: boolean
}
export const NotificationTitle: FC<NotificationTitleProps> = ({ title, date, expanded, onClick }) => (
  <div
    onClick={onClick}
    className={cn(
      'flex justify-between items-center font-bold w-full pb-4 px-3 text-lg text-primary-base cursor-pointer'
    )}
  >
    <div className="flex gap-8">
      <p>{getDateFromISODate({ isoDate: date })}</p>
      <p className={cn({ 'text-base-normal-light': expanded })}>{title}</p>
    </div>
    <ExpandToggle expanded={expanded} />
  </div>
)
