import { FC } from 'react'
import { InfoSectionTitle } from '@/root/modules/bluewave/AdditionalInfo/components/InfoSectionTitle/InfoSectionTitle'
import { InfoSectionInputField } from '@/root/modules/bluewave/AdditionalInfo/components/InfoSectionInputField/InfoSectionInputField'
import { FieldValues, useForm } from 'react-hook-form'
import { InfoField, Info, attributeType } from '@/root/shared-types'
import cn from 'classnames'

interface InfoSectionProps {
  info: Info
  attributes: InfoField[]
  additionalInfo: InfoField[]
  seqN: number
  reservationId: number
  attributeType: attributeType
  enableEditMode: boolean
}
export const InfoSection: FC<InfoSectionProps> = ({
  attributes,
  info,
  seqN,
  reservationId,
  attributeType,
  enableEditMode,
  additionalInfo,
}) => {
  const defaultValues = [...attributes, ...additionalInfo].reduce(
    (defaultValues, attribute) => ({ ...defaultValues, [attribute.id]: attribute.value }),
    {}
  )

  const { watch, control, resetField } = useForm<FieldValues>({ defaultValues })

  const isSectionFilled = [...attributes, ...additionalInfo].every((atr) => atr.value || atr.placeholder)

  return (
    <div className="flex flex-1 flex-col items-center gap-6 pt-3 md:items-stretch ">
      <InfoSectionTitle {...info} isSectionFilled={isSectionFilled} />
      <form
        className={cn('pl-[14px] flex flex-col gap-6', { 'flex-col-reverse': !enableEditMode })}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <div className="flex flex-col gap-6">
          {attributes.map(({ title, id, type, options, placeholder, editable, value, isAttribute }) => (
            <InfoSectionInputField
              title={title}
              id={id}
              watch={watch}
              key={id}
              type={type}
              options={options}
              isDone={type === 'file' ? Boolean(placeholder) : Boolean(value)}
              seqN={seqN}
              reservationId={reservationId}
              attributeType={attributeType}
              placeholder={placeholder}
              control={control}
              editable={editable}
              value={value}
              editMode={enableEditMode}
              isAttribute={isAttribute}
              resetField={resetField}
            />
          ))}
        </div>
        <div className="flex flex-col gap-6">
          {additionalInfo?.map(({ title, id, type, options, placeholder, editable, value, editMode, isAttribute }) => (
            <InfoSectionInputField
              title={title}
              id={id}
              watch={watch}
              key={id}
              type={type}
              options={options}
              isDone={type === 'file' ? Boolean(placeholder || watch(id)?.length) : Boolean(value)}
              seqN={seqN}
              reservationId={reservationId}
              attributeType={attributeType}
              placeholder={placeholder}
              control={control}
              editable={editable}
              value={value}
              editMode={editMode}
              isAttribute={isAttribute}
              resetField={resetField}
            />
          ))}
        </div>
      </form>
    </div>
  )
}
