import { axios } from '@/configs'

import { attributeType, InfoFieldType } from '@/root/shared-types'
import useSWRMutation from 'swr/mutation'
import { useCustomRouter } from '@/root/shared-hooks'

interface usePostAttributeProps {
  reservationId: number
  seqN: number
  attribute: string
  value: string | FileList
  attributeType: attributeType
  type?: InfoFieldType
}

export function usePostAttribute({
  attribute,
  attributeType,
  seqN,
  value,
  reservationId,
  type,
}: usePostAttributeProps) {
  const {
    query: { reservationToken },
    isReady,
    locale,
  } = useCustomRouter()

  const postAttribute = async (body: usePostAttributeProps) => {
    if (body.type === 'file') {
      const file = body.value[0] as File
      const formData = new FormData()
      formData.append('file', file)

      const { data } = await axios.post(
        `documents/${body.reservationId}/${body.seqN}/${body.attribute}/upload`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
      await axios.post(`post-attribute`, {
        ...body,
        value: `minio://bluewave/reservations/${body.reservationId}/guests/${body.seqN}/${body.attribute}`,
        fileName: file.name,
        size: file.size,
        mimeType: 'application/json',
        etag: data.etag,
      })
      return data
    }
    const { data } = await axios.put(`post-attribute`, body)
    return data
  }

  const { trigger: saveAttribute } = useSWRMutation(isReady && `/reservation/${reservationToken}/${locale}`, () =>
    postAttribute({ reservationId, seqN, attribute, value, attributeType, type })
  )
  return { saveAttribute }
}
