import { StepCompletedIcon } from '@/root/ui/admaritime'
import { ButtonHTMLAttributes, FC } from 'react'

interface StepCompletedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string
}

export const StepCompletedButton: FC<StepCompletedButtonProps> = ({ color, ...props }) => (
  <button className="z-10 pb-9" {...props}>
    <StepCompletedIcon color={color} />
  </button>
)

export default StepCompletedButton
