import { FC } from 'react'
import { Question } from '@/root/shared-types'
import { ContentContainer } from '@/root/modules/bluewave/ContentPage/components/ContentContainer/ContentContainer'
import { Question as SingleQuestion } from '../Question/Question'
import { PageTitle } from '@/root/modules/bluewave/ContentPage/components/PageTitle/PageTitle'
import { useGetTranslations } from '@/root/shared-hooks'

interface FAQPageProps {
  questions: Question[]
}

export const FAQPage: FC<FAQPageProps> = ({ questions }) => {
  const { getTranslation } = useGetTranslations()
  return (
    <ContentContainer>
      <PageTitle title={getTranslation('staticContentFaq')} />
      <div className="flex w-full flex-col items-center">
        {questions.map(({ body, title, id }) => (
          <SingleQuestion body={body} title={title} key={id} />
        ))}
      </div>
    </ContentContainer>
  )
}
