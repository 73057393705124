import { FC } from 'react'
import { TicketProps } from '@/root/shared-types/reservation/reservation-types'
import { PrintedTicketPart } from '@/root/modules/bluewave/PrintedTicket/components/PrintedTicketPart/PrintedTicketPart'
import { TermsAndConditions } from '@/root/modules/bluewave/PrintedTicket/components/TermsAndConditions/TermsAndConditions'
import { getTermsByLocale } from '@/root/shared-helpers'
import { PaymentInfo } from '@/root/modules/bluewave/PrintedTicket/components/PaymentInfo/PaymentInfo'
import { useCustomRouter } from '@/root/shared-hooks'
import { Divider } from '@/root/ui/shared/Divider/Divider'

const PrintedTicket: FC<TicketProps> = ({
  ticketData: {
    there,
    back,
    customerInfo,
    reservationId,
    status,
    isRoundTrip,
    terms,
    paymentInfo: { totalPrice },
  },
}) => {
  const { locale } = useCustomRouter()
  const currentLangTerms = getTermsByLocale(terms, locale)
  return (
    <>
      <div className="rounded-lg border">
        <PrintedTicketPart
          reservationId={reservationId}
          status={status}
          {...there}
          customerInfo={customerInfo}
          routeToScrollOnMobile
        />

        {isRoundTrip && (
          <div className="relative">
            <Divider variant={'default'} />
            <PrintedTicketPart reservationId={reservationId} status={status} {...back} />
          </div>
        )}
      </div>

      {status === 'OK' && currentLangTerms?.ticketTerms && (
        <TermsAndConditions conditionsInnerHTML={currentLangTerms.ticketTerms} />
      )}

      {status === 'BOOKED' && <PaymentInfo ticketNumber={reservationId} paymentAmount={totalPrice.amountFormatted} />}
    </>
  )
}

export default PrintedTicket
