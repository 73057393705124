import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'

interface PaymentInfoProps {
  ticketNumber: number
  paymentAmount: string
}
export const PaymentInfo: FC<PaymentInfoProps> = ({ ticketNumber, paymentAmount }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <div className="space-y-5 rounded-md border border-neutral-dark bg-neutral-lightest py-5 text-center font-bold text-black">
      <h1 className="text-lg">{getTranslation('ticketPaymentInfoTitle').toUpperCase()}</h1>
      <div className="flex justify-center gap-5">
        <div className="flex-col text-right font-normal">
          <p>{getTranslation('ticketPaymentRecipient')}</p>
          <p>{getTranslation('ticketPaymentRecipientBankAccountNr')}</p>
          <p>{getTranslation('ticketPaymentCIF')}</p>
          <p>{getTranslation('ticketPaymentCurrencyKey')}</p>
          <p>{getTranslation('ticketPaymentRecipientBank')}</p>
          <p>{getTranslation('ticketPaymentRecipientBankCode')}</p>
          <p>{getTranslation('ticketPaymentDescription')}</p>
          <p>{getTranslation('ticketPaymentAmount')}</p>
        </div>
        <div className="flex-col text-left font-normal">
          <p>{getTranslation('ticketPaymentRecipientName')}</p>
          <p>{getTranslation('ticketPaymentRecipientBankAccountNrValue')}</p>
          <p>{getTranslation('ticketPaymentCIFValue')}</p>
          <p>{getTranslation('ticketPaymentCurrency')}</p>
          <p>{getTranslation('ticketPaymentRecipientBankName')}</p>
          <p>{`${getTranslation('ticketPaymentSwiftCode')}: ${ticketNumber}`}</p>
          <p>{`${getTranslation('ticketBookingNumber')}: ${ticketNumber}`}</p>
          <p>{paymentAmount}</p>
        </div>
      </div>
    </div>
  )
}
