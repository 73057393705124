import React, { useState, ChangeEvent, FC, useEffect } from 'react'
import Tab from './Tab'
import { FormType } from '@/root/shared-types/companyInfoPage/company-info-types'

interface TabsProps {
  children: JSX.Element[]
  companyForm?: boolean
  privatePersonForm?: boolean
}

const Tabs: FC<TabsProps> = ({ children, companyForm, privatePersonForm }) => {
  const initialTab: FormType = companyForm ? 'Invoice to Company' : 'Private person'

  const [activeTab, setActiveTab] = useState<FormType>(initialTab)

  useEffect(() => {
    if (companyForm) {
      setActiveTab('Invoice to Company')
    } else if (privatePersonForm) {
      setActiveTab('Private person')
    }
  }, [companyForm, privatePersonForm])

  const handleTabChange = (event: ChangeEvent<HTMLInputElement>) => {
    setActiveTab(event.target.value as FormType)
  }

  const showTabs = companyForm && privatePersonForm

  if (!companyForm && !privatePersonForm) {
    return <p className="p-6 text-left text-xl">Please, make necessary configuration settings</p>
  }

  return (
    <div className="tabs">
      {showTabs && (
        <ul className="flex flex-col md:flex-row">
          {children.map((child) => {
            const { label, id } = child.props
            return (
              activeTab && (
                <Tab activeTab={activeTab} key={label} label={label as FormType} onChange={handleTabChange} id={id} />
              )
            )
          })}
        </ul>
      )}
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.id === activeTab) {
            return child
          }
          return null
        })}
      </div>
    </div>
  )
}

export default Tabs
