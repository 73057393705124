import { FC } from 'react'

import { Page } from '@/root/shared-types'
import { ContentContainer } from '@/root/modules/bluewave/ContentPage/components/ContentContainer/ContentContainer'
import { PageTitle } from '@/root/modules/bluewave/ContentPage/components/PageTitle/PageTitle'

type DefaultPageProps = Page
export const DefaultPage: FC<DefaultPageProps> = ({ subject, body }) => {
  return (
    <ContentContainer>
      <PageTitle title={subject} />
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
    </ContentContainer>
  )
}
