import { ContentContainer } from '@/root/modules/bluewave/ContentPage/components/ContentContainer/ContentContainer'
import { FC } from 'react'
import { Fleet } from '@/root/shared-types'
import { PageTitle } from '@/root/modules/bluewave/ContentPage/components/PageTitle/PageTitle'
import { Vessel } from '@/root/modules/bluewave/ContentPage/components/Vessel/Vessel'

export const FleetPage: FC<Fleet> = ({ subject, vessels }) => {
  return (
    <ContentContainer>
      <PageTitle title={subject} />
      <div className="flex w-full flex-wrap gap-8 ">
        {vessels.map(({ name, images, id, slug }) => (
          <Vessel name={name} images={images} key={id} slug={slug} />
        ))}
      </div>
    </ContentContainer>
  )
}
