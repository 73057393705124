import { ActionButton } from '@/root/modules/bluewave/TicketActionButtons/components/ActionButton'
import { FC } from 'react'
import { useActionsButtonHandlers } from '@/root/shared-hooks/reservation/useActionsButtonHandlers'

interface TicketActionButtonsProps {
  reservationId: number
  reservationToken: string
}
const TicketActionButtons: FC<TicketActionButtonsProps> = ({ reservationId, reservationToken }) => {
  const { onDownload, onPrint, iframeProps } = useActionsButtonHandlers(reservationId, reservationToken)

  return (
    <div className=" flex justify-around md:justify-center md:gap-48">
      <ActionButton action="download" onClick={onDownload} />
      <ActionButton action="print" onClick={onPrint} />
      <iframe {...iframeProps} />
    </div>
  )
}

export default TicketActionButtons
