import React, { FC, useMemo } from 'react'
import Downshift from 'downshift'
import { Country, CountryAutocompleteProps } from '@/root/shared-types/companyInfoPage/company-info-types'
import { getLocalStorageValueFor, saveInLocalStorage } from './utils'
import { DEFAULT_COUNTRY } from './const'
import cn from 'classnames'
import IconFlag from './IconFlag'
import { CustomerInfoArrowDropdownIcon } from '@/root/ui/shared/icons/CustomerInfoArrowDropdownIcon'

const CountryAutocomplete: FC<CountryAutocompleteProps> = ({ handleSelect, id, countries, locale }) => {
  const sortedCountries = useMemo(() => {
    const preferredCountries =
      locale === 'en' ? ['Costa Rica', 'El Salvador', 'Panama'] : ['Costa Rica', 'El Salvador', 'Panamá']
    return [...countries].sort((a, b) => {
      const aPriority = preferredCountries.includes(a.title) ? 0 : 1
      const bPriority = preferredCountries.includes(b.title) ? 0 : 1
      return aPriority - bPriority || a.title.localeCompare(b.title)
    })
  }, [countries, locale])

  const getInitialSelectedItem = (id: string) => {
    if (typeof window !== 'undefined') {
      const savedSelectedItem = getLocalStorageValueFor(id)
      return savedSelectedItem ? JSON.parse(savedSelectedItem) : DEFAULT_COUNTRY
    }
    return DEFAULT_COUNTRY
  }

  const handleChange = (selectedItem: Country = DEFAULT_COUNTRY) => {
    handleSelect(selectedItem)
    saveInLocalStorage(`${id}`, JSON.stringify(selectedItem))
  }

  return (
    <Downshift
      id={id}
      itemToString={(item) => (item ? item.title : '')}
      initialSelectedItem={getInitialSelectedItem(id)}
      onSelect={(selection) => handleChange(selection)}
    >
      {({ getInputProps, getItemProps, getMenuProps, isOpen, toggleMenu, inputValue, selectedItem }) => {
        const handleClick = () => {
          toggleMenu()
        }

        return (
          <div className="relative">
            <div
              onClick={handleClick}
              className="box border-neutral-normal-lighter focus-within:border-neutral-normal-lighter relative rounded border border-solid"
            >
              <div className="absolute inset-y-3 left-2">
                {selectedItem?.code && (
                  <IconFlag
                    alt="countryFlag"
                    width={21}
                    height={14}
                    countryCode={selectedItem.code.toLowerCase()}
                    src={''}
                  />
                )}
              </div>
              <button type="button" className="absolute inset-y-4 right-2">
                <CustomerInfoArrowDropdownIcon />
              </button>
              <input
                id={id}
                {...getInputProps()}
                className="h-10 w-full grow rounded border-none pl-16 pr-10 text-start font-bold outline-none"
                required
              />
            </div>

            {isOpen && (
              <div className="h-230 absolute top-10 z-20 w-full overflow-scroll">
                <ul
                  {...getMenuProps()}
                  className="border-neutral-normal-lighter max-h-44 w-full content-start overflow-scroll rounded border bg-white text-left text-lg"
                >
                  {sortedCountries
                    .filter((country) => !inputValue || country.title.toLowerCase().includes(inputValue.toLowerCase()))
                    .map((country, index) => (
                      <li
                        key={country.code}
                        {...getItemProps({
                          index,
                          item: country,
                        })}
                        className={cn('p-2 flex items-center pr-2 pl-2', {
                          'bg-neutral-medium-lighter font-bold': selectedItem === country,
                          'bg-white': selectedItem !== country,
                          'hover:bg-neutral-normal-lighter': true,
                        })}
                      >
                        <div className="pr-6">
                          <IconFlag alt="countryFlag" width={21} countryCode={country.code.toLowerCase()} src={''} />
                        </div>
                        <div className="flex grow items-center text-left text-lg">{country.title}</div>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        )
      }}
    </Downshift>
  )
}

export default CountryAutocomplete
