import { FC } from 'react'
import Image from 'next/image'
import { ContentContainer } from '@/root/modules/bluewave/ContentPage/components/ContentContainer/ContentContainer'
import { PageTitle } from '@/root/modules/bluewave/ContentPage/components/PageTitle/PageTitle'
import { ImageProps } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'
import { ArrowIconBlue } from '@/root/ui/shared/icons/ArrowIconBlue'

interface VesselProps {
  body: string
  images: ImageProps[]
  vesselName: string
}
export const Vessel: FC<VesselProps> = ({ body, images, vesselName }) => {
  const { getTranslation } = useGetTranslations()
  return (
    <ContentContainer className="gap-8 pb-20 pl-16 pr-10 pt-0">
      <div className="mb-8 flex flex-wrap gap-4">
        <PageTitle title={getTranslation('vesselPageTitle')} />
        <ArrowIconBlue />
        <PageTitle title={vesselName} />
      </div>

      <div className="flex flex-col gap-6 md:flex-row">
        <div className="flex flex-col gap-5 font-medium md:w-1/2" dangerouslySetInnerHTML={{ __html: body }} />
        <div>
          {images.map((image) => (
            <Image src={'/api' + image.url} alt={image.alternativeText} width={450} height={338} key={image.id} />
          ))}
        </div>
      </div>
    </ContentContainer>
  )
}
