// components/CustomFileInput.tsx
import React, { FC } from 'react'
import { DynamicButton } from '@/root/components/bluewave/DynamicButton/DynamicButton'
import { InputProps } from '@/root/shared-types'
import { useRef } from 'react'
import cn from 'classnames'

import { useGetTranslations } from '@/root/shared-hooks'
export const FileInput: FC<InputProps> = ({
  onSave,
  control: { register },
  id,
  disabled,
  placeholder,
  disableOnSave,
  isLoading,
}) => {
  const fileRef = useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register(id)

  const { getTranslation } = useGetTranslations()

  const onClick = () => {
    if (disabled) {
      fileRef?.current?.click()
    }
    onSave()
  }

  return (
    <>
      <div className="flex w-[95%] md:max-w-screen-xs ">
        <div className="flex h-9 flex-1 items-center truncate whitespace-nowrap rounded-l border border-neutral-normal-lighter px-2 ">
          <span>{placeholder || getTranslation('chooseFiles')}</span>
          <input
            type="file"
            className="hidden"
            {...rest}
            ref={(element) => {
              ref(element)
              fileRef.current = element
            }}
          />
        </div>
        <DynamicButton
          saved={!disabled}
          typeOfButton="file"
          className={cn({ 'min-w-[138px]': disabled })}
          onClick={onClick}
          disabled={disableOnSave}
          isLoading={isLoading}
        />
      </div>
      {isLoading && <p className="text-xs text-neutral">{getTranslation('uploadingFile')}</p>}
    </>
  )
}
