import { CustomerInfo } from '@/root/modules/bluewave/PrintedTicket/components/CustomerInfo/CustomerInfo'
import { DepartureAndVesselInfo } from '@/root/modules/bluewave/PrintedTicket/components/DepartureAndVesselInfo/DepartureAndVesselInfo'
import { RouteInfo } from '@/root/modules/bluewave/PrintedTicket/components/RouteInfo/RouteInfo'
import TicketItemsWithQR from '@/root/modules/bluewave/PrintedTicket/components/TicketItemsWithQR/TicketItemsWithQR'
import { FC } from 'react'
import { BookingStatus, CustomerInfoProps, TicketPartProps } from '@/root/shared-types/reservation/reservation-types'
import { DateTime } from 'luxon'
import { RowContainer } from '../RowContainer/RowContainer'

interface PrintedTicketPartProps extends TicketPartProps {
  status: BookingStatus
  reservationId: number
  additionalInfo?: string
  customerInfo?: CustomerInfoProps
  routeToScrollOnMobile?: boolean
}

export const PrintedTicketPart: FC<PrintedTicketPartProps> = ({
  reservationId,
  vesselTitle,
  numOfPassengers,
  numOfCars,
  sailRefId,
  items,
  vehicles,
  status,
  departurePort,
  arrivalPort,
  departure,
  customerInfo,
  additionalInfo,
  routeToScrollOnMobile,
}) => {
  const departureValue = departure ? DateTime.fromISO(departure).toFormat('dd.MM.yyyy - hh:mm a') : null
  const route = `${departurePort} — ${arrivalPort}`.toUpperCase()

  return (
    <div className="px-3 md:px-16 md:py-[10px]">
      {customerInfo ? (
        <CustomerInfo {...customerInfo} reservationId={reservationId} status={status} />
      ) : (
        <RowContainer />
      )}
      {departureValue && (
        <DepartureAndVesselInfo
          numOfPassengersThere={numOfPassengers}
          numOfCarsThere={numOfCars}
          vesselTitleThere={vesselTitle}
          departureValue={departureValue}
        />
      )}
      <RouteInfo route={route} />
      <TicketItemsWithQR
        items={items}
        reservationId={reservationId}
        sailRefId={sailRefId}
        vehicles={vehicles}
        displayQR={status === 'OK'}
        hideBorder={Boolean(!additionalInfo)}
        routeToScrollOnMobile={routeToScrollOnMobile}
      />

      {Boolean(additionalInfo) && <p>{additionalInfo}</p>}
    </div>
  )
}
