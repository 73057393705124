import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { FC } from 'react'

interface IconProps extends ImageProps {
  countryCode?: string | undefined
}

const IconFlag: FC<IconProps> = ({ countryCode, alt, width, height = 0 }) => {
  if (!countryCode) return null
  return <Image alt={alt} src={`/bluewave/flags/${countryCode.toLowerCase()}.png`} width={width} height={height} />
}

export default IconFlag
