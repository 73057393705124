import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import cn from 'classnames'
import { BookingStep } from '@/root/shared-types'
import { bookingSteps } from '@/root/shared-consts'

interface StepLabelsProps {
  currentStep: BookingStep
}

const { SELECT_DATE_TIME, CONFIRM } = bookingSteps

const StepLabels: FC<StepLabelsProps> = ({ currentStep }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <div className="mt-[-26px] flex justify-between gap-6 text-center text-xs">
      <p className="text-base">{getTranslation('destinationStep')}</p>
      <p className={cn(currentStep !== SELECT_DATE_TIME ? 'text-base' : 'text-adm-gray-480')}>
        {getTranslation('selectTicketStep')}
      </p>
      <p className={cn(currentStep === CONFIRM ? 'text-base' : 'text-adm-gray-480')}>
        {getTranslation('selectConfirmStep')}
      </p>
    </div>
  )
}

export default StepLabels
