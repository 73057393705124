import { FC, useState } from 'react'
import { NotificationBody } from '../NotificationBody/NotificationBody'
import { ImageProps } from '@/root/shared-types'
import { NotificationTitle } from '../NotificationTitle/NotificationTitle'

interface NotificationProps {
  title: string
  body: string
  date: string
  image: ImageProps
}
export const Notification: FC<NotificationProps> = ({ image, body, title, date }) => {
  const [expanded, setExpanded] = useState(false)
  const onExpand = () => setExpanded((prevState) => !prevState)
  return (
    <div className="flex w-full flex-col items-center gap-3 border-b border-neutral-medium-lighter pb-12 text-primary-base first:border-none">
      <NotificationTitle title={title} onClick={onExpand} expanded={expanded} date={date} />
      {expanded && <NotificationBody body={body} image={image} />}
    </div>
  )
}
