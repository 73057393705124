import { FC, useState } from 'react'
import { ExpandToggle } from '@/root/components/bluewave/ExpandToggle/ExpandToggle'
import cn from 'classnames'

interface QuestionProps {
  title: string
  body: string
}
export const Question: FC<QuestionProps> = ({ title, body }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className="w-full  border-b border-neutral-medium-lighter px-8 text-primary-base first:border-t">
      <div
        className="flex h-16 cursor-pointer items-center justify-between font-bold"
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        <h1 className={cn({ 'text-primary-base': !expanded }, { 'text-bw-primary-Light': expanded }, 'text-lg')}>
          {title}
        </h1>
        <ExpandToggle expanded={expanded} />
      </div>
      {expanded && <div className="mr-6 pb-4 text-lg text-black" dangerouslySetInnerHTML={{ __html: body }} />}
    </div>
  )
}
