import { FC, HTMLAttributes } from 'react'
import { Header } from '@/root/modules/bluewave/AdditionalInfo/components/Header/Header'
import { InfoSection } from '@/root/modules/bluewave/AdditionalInfo/components/InfoSection/InfoSection'
import { AdditionalInfoData } from '@/root/shared-types'
import cn from 'classnames'

interface AdditionalFieldsProps extends HTMLAttributes<HTMLDivElement> {
  additionalInfoData: AdditionalInfoData
  allSectionsFilled: boolean
}
const AdditionalFields: FC<AdditionalFieldsProps> = ({
  additionalInfoData: { routInfo, attributes, reservationId },
  allSectionsFilled,
}) => {
  return attributes?.map(({ vehicleInfo, driverInfo }) => {
    const ifInfoProvided =
      vehicleInfo.attributes.every((atr) => {
        return atr.value
      }) &&
      (driverInfo === null
        ? true
        : driverInfo?.every((dI) =>
            [...dI.attributes, ...dI.additionalInfo].every((atr) => atr.value || atr.placeholder)
          ))
    return (
      <div
        className={cn(
          'border-2 rounded pb-8',
          { 'border-error': !ifInfoProvided },
          { 'border-success-dark': ifInfoProvided }
        )}
        key={vehicleInfo.seqN}
      >
        {routInfo && <Header route={routInfo} isInfoProvided={ifInfoProvided} />}
        <div className="flex flex-col justify-between md:flex-row md:px-6">
          <>
            <InfoSection
              attributes={vehicleInfo.attributes}
              additionalInfo={vehicleInfo.additionalInfo}
              info={vehicleInfo.info}
              seqN={vehicleInfo.seqN}
              reservationId={reservationId}
              attributeType="vehicle"
              enableEditMode={allSectionsFilled}
            />
            {driverInfo?.map((driver) => (
              <InfoSection
                key={driver.seqN}
                attributes={driver.attributes}
                additionalInfo={driver.additionalInfo}
                info={driver.info}
                seqN={driver.seqN}
                reservationId={reservationId}
                attributeType="guest"
                enableEditMode={allSectionsFilled}
              />
            ))}
          </>
        </div>
      </div>
    )
  })
}

export default AdditionalFields
