import Image from 'next/image'
import { FC } from 'react'
import { ImageProps } from '@/root/shared-types'
import { useCustomRouter } from '@/root/shared-hooks'

interface VesselProps {
  images: ImageProps[]
  name: string
  slug: string
}

export const Vessel: FC<VesselProps> = ({ images, name, slug }) => {
  const router = useCustomRouter()

  const handleClick = () => {
    router.push(`/content/fleet/${slug}`)
  }

  return (
    <button className="flex w-fit flex-col items-center gap-3 font-semibold tracking-tighter" onClick={handleClick}>
      <Image src={'/api' + images[0].url} alt={name} width={356} height={265} />
      <p className="flex justify-center text-2xl font-normal ">{name}</p>
    </button>
  )
}
