import React, { FC } from 'react'
import { RadioInputProps } from '@/root/shared-types/companyInfoPage/company-info-types'

const RadioInput: FC<RadioInputProps> = ({ label, checked, onChange, id }) => {
  return (
    <div className="mt-10 flex flex-row items-center pl-9 pr-7">
      <label className="flex items-center pr-2">
        <input type="radio" value={id} checked={checked} onChange={onChange} className="hidden" />
        <div className="border-secondary relative size-5 rounded-full border-2">
          <div
            className={`bg-secondary absolute left-1/2 top-1/2 size-3 -translate-x-1/2 -translate-y-1/2 rounded-full${
              checked ? ' opacity-100' : ' opacity-0'
            }`}
          ></div>
        </div>
        <span className="text-primary-base pl-4 text-xl font-semibold">{label}</span>
      </label>
    </div>
  )
}

export default RadioInput
