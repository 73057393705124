import { Country, CodeAndTitle } from '@/root/shared-types/companyInfoPage/company-info-types'

export const DEFAULT_COUNTRY: Country = {
  code: 'PA',
  title: 'Panama',
  titles: {
    en: 'Panama',
    es: 'Panamá',
  },
}

export const DEFAULT_PHONE: CodeAndTitle = {
  code: 'PA',
  title: '507',
}

export const CompanyFormFields = {
  LEGAL_NAME: 'legalName',
  RUC: 'ruc',
  TAX_NUMBER: 'taxNumber',
  COUNTRY: 'country',
  INTL_CODE: 'intlCode',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  ZIP: 'zip',
} as const

export const PersonFormFields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  INTL_CODE: 'intlCode',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
} as const
