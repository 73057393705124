import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useGetTranslations } from '@/root/shared-hooks'
import { DEFAULT_PHONE } from './const'
import PhoneAutocomplete from '@/root/pages/bluewave/CustomerInfo/PhoneAutocomplete'
import GeneralInput from '@/root/pages/bluewave/CustomerInfo/GeneralInput'
import { CompanyFormFields, PersonFormFields } from './const'
import {
  emailValidationPattern,
  firstNameValidationPattern,
  lastNameValidationPattern,
  phoneValidationPattern,
} from './utils'
import {
  PersonFormProps,
  PersonFormKeys,
  Owner,
  CodeAndTitle,
} from '@/root/shared-types/companyInfoPage/company-info-types'
import { axios } from '@/configs'
import 'react-notifications-component/dist/theme.css'
import 'react-notifications/lib/notifications.css'
import { NotificationManager } from 'react-notifications'
import useSWR from 'swr'
import { Loader } from '@/root/ui/bluewave'
import PhoneGeneralInput from '@/root/pages/bluewave/CustomerInfo/PhoneGeneralInput'

interface FetchResponse {
  success: boolean
  error?: unknown
}

export interface FetchPhonesResponse extends FetchResponse {
  phones?: CodeAndTitle[]
}

const fetchData = async <T extends FetchResponse>(url: string, key: keyof T): Promise<T> => {
  try {
    const response = await axios.get<{ items: T[keyof T][] }>(url)
    return { success: true, [key]: response.data.items } as unknown as T
  } catch (error) {
    return { success: false, error } as T
  }
}

const fetchPhones = (): Promise<FetchPhonesResponse> => {
  return fetchData<FetchPhonesResponse>('/get-phones/phones', 'phones')
}

const PersonForm: FC<PersonFormProps> = ({ reservationId }) => {
  const { data: phonesData, error: phonesError } = useSWR('get-phones', fetchPhones)
  const { getTranslation } = useGetTranslations()
  const [phone, setPhone] = useState<CodeAndTitle>(DEFAULT_PHONE)

  useEffect(() => {
    const savedPhone = localStorage.getItem('selectedPhone')
    if (savedPhone) {
      setPhone(JSON.parse(savedPhone))
    }
  }, [])

  const {
    handleSubmit,
    register,
    clearErrors,
    watch,
    reset,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: {
      [CompanyFormFields.INTL_CODE]: DEFAULT_PHONE.title,
    },
  })

  useEffect(() => {
    setValue(CompanyFormFields.INTL_CODE, phone.title)
  }, [phone, setValue])

  if (phonesError) {
    return <div>Failed to load data</div>
  }

  if (!phonesData) {
    return (
      <div className="flex justify-center py-40">
        <Loader />
      </div>
    )
  }

  const postOwner = async (reservationId: number, ownerData: Owner) => {
    try {
      const { data } = await axios.put(`/set-reservation-owner/${reservationId}`, ownerData)
      return { success: true, data }
    } catch (error) {
      return { success: false, error }
    }
  }

  const handlePersonForm = async (data: FieldValues) => {
    reset()
    const { firstName, lastName, email, intlCode, phoneNumber } = data
    const { success } = await postOwner(reservationId, {
      firstName,
      lastName,
      email,
      phone: {
        intlCode,
        phoneNumber,
      },
    })

    if (success) {
      NotificationManager.success(getTranslation('confirmPageRequestSuccess'))
    } else {
      NotificationManager.error(getTranslation('confirmPageRequestError'))
    }
  }

  const handleErrorsPersonForm = (event: ChangeEvent<HTMLInputElement>) =>
    clearErrors(event.target.name as PersonFormKeys)

  const phoneCodesToShow =
    phonesData && phonesData.phones ? phonesData.phones.filter((phone: CodeAndTitle) => phone.code && phone.title) : []

  return (
    <form onSubmit={handleSubmit(handlePersonForm)} className="grid grid-cols-1 pt-10 md:grid-cols-2">
      <div className="max-w-490 flex flex-col justify-start px-10">
        <GeneralInput
          label={getTranslation('confirmPageFirstName')}
          id={PersonFormFields.FIRST_NAME}
          onChange={handleErrorsPersonForm}
          hasError={Boolean(errors[PersonFormFields.FIRST_NAME])}
          errorMessage={
            errors[PersonFormFields.FIRST_NAME]?.message === 'Invalid format'
              ? getTranslation('confirmPageErrorMessage')
              : getTranslation('confirmPageRequiredField')
          }
          validationPattern={firstNameValidationPattern}
          register={register}
          setValue={setValue}
          watch={watch}
          touchedFields={touchedFields}
        />

        <GeneralInput
          label={getTranslation('confirmPageSurname')}
          id={PersonFormFields.LAST_NAME}
          onChange={handleErrorsPersonForm}
          hasError={Boolean(errors[PersonFormFields.LAST_NAME])}
          errorMessage={
            errors[PersonFormFields.LAST_NAME]?.message === 'Invalid format'
              ? getTranslation('confirmPageErrorMessage')
              : getTranslation('confirmPageRequiredField')
          }
          validationPattern={lastNameValidationPattern}
          register={register}
          setValue={setValue}
          watch={watch}
          touchedFields={touchedFields}
        />
      </div>

      <div className="max-w-490 flex flex-col justify-between px-10">
        <div className="relative">
          <div className="grid grid-cols-1 md:grid-cols-[1fr,2fr]">
            <div className="min-w-140 flex items-start">
              <label htmlFor="phone" className="w-160 items-center">
                {getTranslation('confirmPagePersonPhoneNumber')}
              </label>
            </div>
            <div className="grid grid-cols-[1fr,2fr] gap-1.5 md:grid-cols-[1fr,1fr]">
              <PhoneAutocomplete
                id={PersonFormFields.INTL_CODE}
                register={register}
                phones={phoneCodesToShow}
                handleSelect={setPhone}
              />
              <PhoneGeneralInput
                id={PersonFormFields.PHONE_NUMBER}
                onChange={handleErrorsPersonForm}
                hasError={Boolean(errors[PersonFormFields.PHONE_NUMBER])}
                errorMessage={
                  errors[PersonFormFields.PHONE_NUMBER]?.message === 'Invalid format'
                    ? getTranslation('confirmPageErrorMessage')
                    : getTranslation('confirmPageRequiredField')
                }
                validationPattern={phoneValidationPattern}
                register={register}
                setValue={setValue}
                watch={watch}
                touchedFields={touchedFields}
              />
            </div>
          </div>
        </div>

        <GeneralInput
          label={getTranslation('confirmPagePersonEmail')}
          id={PersonFormFields.EMAIL}
          onChange={handleErrorsPersonForm}
          hasError={Boolean(errors[PersonFormFields.EMAIL])}
          errorMessage={
            errors[PersonFormFields.EMAIL]?.message === 'Invalid format'
              ? getTranslation('confirmPageErrorMessage')
              : getTranslation('confirmPageRequiredField')
          }
          validationPattern={emailValidationPattern}
          register={register}
          setValue={setValue}
          watch={watch}
          touchedFields={touchedFields}
        />
      </div>
    </form>
  )
}

export default PersonForm
