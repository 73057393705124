import { FC } from 'react'
import { News } from '@/root/shared-types'
import { Notification } from '@/root/modules/bluewave/ContentPage/components/Notification/Notification'
import { ContentContainer } from '@/root/modules/bluewave/ContentPage/components/ContentContainer/ContentContainer'
import { PageTitle } from '@/root/modules/bluewave/ContentPage/components/PageTitle/PageTitle'
import { useGetTranslations } from '@/root/shared-hooks'

export const NewsPage: FC<{ news: News[] }> = ({ news }) => {
  const { getTranslation } = useGetTranslations()
  return (
    <ContentContainer>
      <PageTitle title={getTranslation('staticContentNews')} />
      <div className="flex w-full flex-col-reverse items-center gap-10">
        {news.map(({ subject, id, image, published_at, body }) => (
          <Notification key={id} body={body} title={subject} image={image} date={published_at} />
        ))}
      </div>
    </ContentContainer>
  )
}
