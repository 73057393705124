import AppLayout from '@/root/modules/bluewave/AppLayout/components/AppLayout/AppLayout'
import PrintedTicket from '@/root/modules/bluewave/PrintedTicket/components/PrintedTicket/PrintedTicket'
import TicketActionButtons from '@/root/modules/bluewave/TicketActionButtons/TicketActionButtons'
import AdditionalFields from '@/root/modules/bluewave/AdditionalInfo/components/AdditionalFields/AdditionalFeilds'
import { StaticPage } from '@/root/modules/bluewave/ContentPage/components/StaticPage/StaticPage'
import { NewsPage } from '@/root/modules/bluewave/ContentPage/components/NewsPage/NewsPage'
import { Vessel } from './Vessel/Vessel'
import { BookingProgressBar } from './BookingProgressBar/BookingProgressBar'

export {
  AppLayout,
  PrintedTicket,
  TicketActionButtons,
  AdditionalFields,
  StaticPage,
  NewsPage,
  Vessel,
  BookingProgressBar,
}
