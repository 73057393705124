import { FC } from 'react'

interface TermsAndConditionsProps {
  conditionsInnerHTML: TrustedHTML | string
}
export const TermsAndConditions: FC<TermsAndConditionsProps> = ({ conditionsInnerHTML }) => (
  <div
    className="mt-6 text-sm [&>a]:underline [&>p>a]:underline"
    dangerouslySetInnerHTML={{ __html: conditionsInnerHTML }}
  />
)
