import { FC } from 'react'
import cn from 'classnames'
import { RouteInfo } from '@/root/shared-types'
import { DoneIcon } from '@/root/ui/shared/icons/DoneIcon'
import { ArrowIconBlue } from '@/root/ui/shared/icons/ArrowIconBlue'
import { WarningIconRed } from '@/root/ui/shared/icons/WarningIconRed'

interface HeaderProps {
  route: RouteInfo
  isInfoProvided: boolean
}

export const Header: FC<HeaderProps> = ({ route: { departurePort, arrivalPort, departure }, isInfoProvided }) => (
  <div className="grid items-center border-b border-b-neutral-normal-lighter px-5 font-bold md:h-16 md:grid-cols-3">
    <span
      className={cn(
        { 'text-success-dark': isInfoProvided },
        `flex flex-row justify-center md:justify-start gap-3 text-error`
      )}
    >
      {isInfoProvided ? <DoneIcon width={19} height={18} /> : <WarningIconRed />}
      <p className="text-sm ">
        {isInfoProvided ? 'Required information is provided' : 'Required information not provided'}
      </p>
    </span>
    <span className="flex justify-center gap-3 text-lg font-bold text-primary-base">
      <p>{departurePort}</p>
      <ArrowIconBlue />
      <p>{arrivalPort}</p>
    </span>
    <p className="text-center text-sm font-bold text-primary-base md:text-end md:text-xl">{departure?.toUpperCase()}</p>
  </div>
)
