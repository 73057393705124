import React, { FC, useState, ChangeEvent, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useGetTranslations } from '@/root/shared-hooks'
import CountryAutocomplete from '@/root/pages/bluewave/CustomerInfo/CountryAutocomplete'
import PhoneAutocomplete from '@/root/pages/bluewave/CustomerInfo/PhoneAutocomplete'
import GeneralInput from '@/root/pages/bluewave/CustomerInfo/GeneralInput'
import { CompanyFormFields, DEFAULT_COUNTRY, DEFAULT_PHONE } from './const'
import {
  emailNumValidationPattern,
  legalNameValidationPattern,
  phoneValidationPattern,
  rucValidationPattern,
  vatNumValidationPattern,
  zipValidationPattern,
} from './utils'
import axios from 'axios'
import {
  CompanyFormKeys,
  CompanyFormProps,
  Country,
  Owner,
  CodeAndTitle,
} from '@/root/shared-types/companyInfoPage/company-info-types'
import 'react-notifications-component/dist/theme.css'
import 'react-notifications/lib/notifications.css'
import { NotificationManager } from 'react-notifications'
import useSWR from 'swr'
import { Loader } from '@/root/ui/bluewave'
import PhoneGeneralInput from '@/root/pages/bluewave/CustomerInfo/PhoneGeneralInput'

interface FetchResponse<T> {
  success: boolean
  error?: unknown
  data?: T[]
}

const fetcher =
  <T,>(url: string) =>
  async (): Promise<FetchResponse<T>> => {
    try {
      const response = await axios.get<{ items: T[] }>(url)
      const actualData = response.data?.items
      return { success: true, data: actualData }
    } catch (error) {
      return { success: false, error }
    }
  }
const CompanyForm: FC<CompanyFormProps> = ({ reservationId, locale }) => {
  const { data: countriesData, error: countriesError } = useSWR(
    `get-countries-${locale}`,
    fetcher<Country>('/api/get-countries/countries')
  )
  const { data: phonesData, error: phonesError } = useSWR(
    `get-phones-${locale}`,
    fetcher<CodeAndTitle>('/api/get-phones/phones')
  )

  const { getTranslation } = useGetTranslations()

  const [country, setCountry] = useState<Country>(DEFAULT_COUNTRY)
  const [phone, setPhone] = useState<CodeAndTitle>(DEFAULT_PHONE)

  useEffect(() => {
    const savedCountry = localStorage.getItem('selectedCountry')
    const savedPhone = localStorage.getItem('selectedPhone')
    if (savedCountry) {
      setCountry(JSON.parse(savedCountry))
    }
    if (savedPhone) {
      setPhone(JSON.parse(savedPhone))
    }
  }, [])

  const {
    handleSubmit,
    register,
    clearErrors,
    setValue,
    watch,
    reset,
    formState: { errors, touchedFields },
  } = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: {
      [CompanyFormFields.COUNTRY]: DEFAULT_COUNTRY.title,
      [CompanyFormFields.INTL_CODE]: DEFAULT_PHONE.title,
    },
  })

  useEffect(() => {
    setValue(CompanyFormFields.COUNTRY, country.title)
  }, [country, setValue])

  useEffect(() => {
    setValue(CompanyFormFields.INTL_CODE, phone.title)
  }, [phone, setValue])

  if (countriesError || phonesError) {
    return <div>Failed to load data</div>
  }

  if (!countriesData || !phonesData) {
    return (
      <div className="flex justify-center py-40">
        <Loader />
      </div>
    )
  }

  const postOwner = async (reservationId: number, ownerData: Owner) => {
    try {
      const { data } = await axios.put(`/api/set-reservation-owner/${reservationId}`, ownerData)
      return { success: true, data }
    } catch (error) {
      return { success: false, error }
    }
  }

  const handleCompanyForm = async (data: FieldValues) => {
    const {
      email,
      intlCode,
      phoneNumber,
      registrationNumber,
      legalName,
      taxNumber,
      ruc,
      city,
      country,
      address,
      zip,
      type,
    } = data
    reset()

    const { success } = await postOwner(reservationId, {
      firstName: 'userName',
      lastName: 'userLastName',
      email,
      phone: {
        intlCode,
        phoneNumber,
      },
      companyInfo: {
        registrationNumber,
        legalName,
        taxNumber,
        ruc,
        city,
        country,
        address,
        zip,
        type,
      },
    })

    if (success) {
      NotificationManager.success(getTranslation('confirmPageRequestSuccess'))
    } else {
      NotificationManager.error(getTranslation('confirmPageRequestError'))
    }
  }

  const handleErrorsCompanyForm = (event: ChangeEvent<HTMLInputElement>) =>
    clearErrors(event.target.name as CompanyFormKeys)

  const countriesToShow =
    countriesData && countriesData.data
      ? countriesData.data
          .filter(
            (country: { titles: { en: string; es: string; et?: string }; code: string }) =>
              country.titles && (country.titles.en || country.titles.es)
          )
          .map((country: { titles: { en: string; es: string; et?: string }; code: string }) => ({
            code: country.code,
            title:
              locale === 'en' ? country.titles.en : locale === 'es' ? country.titles.es : country.titles.et ?? 'N/A',
            titles: country.titles,
          }))
      : []

  const phoneCodesToShow =
    phonesData && phonesData.data ? phonesData.data.filter((phone: CodeAndTitle) => phone.code && phone.title) : []

  return (
    <div>
      <form onSubmit={handleSubmit(handleCompanyForm)} className="grid grid-cols-1 pt-10 md:grid-cols-2">
        <div className="max-w-490 flex flex-col justify-between px-10">
          <GeneralInput
            label={getTranslation('confirmPageCompanyName')}
            id={CompanyFormFields.LEGAL_NAME}
            onChange={handleErrorsCompanyForm}
            hasError={Boolean(errors[CompanyFormFields.LEGAL_NAME])}
            errorMessage={
              errors[CompanyFormFields.LEGAL_NAME]?.message === 'Invalid format'
                ? getTranslation('confirmPageErrorMessage')
                : getTranslation('confirmPageRequiredField')
            }
            validationPattern={legalNameValidationPattern}
            register={register}
            setValue={setValue}
            watch={watch}
            touchedFields={touchedFields}
          />

          <div className="relative mb-8">
            <div className="grid grid-cols-1 md:grid-cols-[1fr,2fr]">
              <div className="min-w-140 flex items-center">
                <label htmlFor="country" className="w-160 items-center">
                  {getTranslation('confirmPageCountry')}
                </label>
              </div>
              <CountryAutocomplete
                locale={locale}
                handleSelect={setCountry}
                id={CompanyFormFields.COUNTRY}
                countries={countriesToShow}
              />
            </div>
          </div>

          {country.title === DEFAULT_COUNTRY.title ? (
            <>
              <GeneralInput
                label={getTranslation('confirmPageRuc')}
                id={CompanyFormFields.RUC}
                onChange={handleErrorsCompanyForm}
                hasError={Boolean(errors[CompanyFormFields.RUC])}
                errorMessage={
                  errors[CompanyFormFields.RUC]?.message === 'Invalid format'
                    ? getTranslation('confirmPageErrorMessage')
                    : getTranslation('confirmPageRequiredField')
                }
                validationPattern={rucValidationPattern}
                register={register}
                setValue={setValue}
                watch={watch}
                touchedFields={touchedFields}
              />
            </>
          ) : (
            <>
              <GeneralInput
                label={getTranslation('confirmPageVatNum')}
                id={CompanyFormFields.TAX_NUMBER}
                onChange={handleErrorsCompanyForm}
                hasError={Boolean(errors[CompanyFormFields.TAX_NUMBER])}
                errorMessage={
                  errors[CompanyFormFields.TAX_NUMBER]?.message === 'Invalid format'
                    ? getTranslation('confirmPageErrorMessage')
                    : getTranslation('confirmPageRequiredField')
                }
                validationPattern={vatNumValidationPattern}
                register={register}
                setValue={setValue}
                watch={watch}
                touchedFields={touchedFields}
              />
            </>
          )}
        </div>

        <div className="max-w-491 flex flex-col justify-start px-10">
          <div className="relative">
            <div className="min-w-490 grid grid-cols-1 md:grid-cols-[1fr,2fr]">
              <div className="min-w-140 flex items-start">
                <label htmlFor="phone" className="w-160 flex items-start">
                  {getTranslation('confirmPageCompanyPhoneNumber')}
                </label>
              </div>
              <div className="grid grid-cols-[1fr,2fr] gap-1.5 md:grid-cols-[1fr,1fr]">
                <PhoneAutocomplete
                  id={CompanyFormFields.INTL_CODE}
                  register={register}
                  phones={phoneCodesToShow}
                  handleSelect={setPhone}
                />
                <PhoneGeneralInput
                  id={CompanyFormFields.PHONE_NUMBER}
                  onChange={handleErrorsCompanyForm}
                  hasError={Boolean(errors[CompanyFormFields.PHONE_NUMBER])}
                  errorMessage={
                    errors[CompanyFormFields.PHONE_NUMBER]?.message === 'Invalid format'
                      ? getTranslation('confirmPageErrorMessage')
                      : getTranslation('confirmPageRequiredField')
                  }
                  validationPattern={phoneValidationPattern}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  touchedFields={touchedFields}
                />
              </div>
            </div>
          </div>

          <GeneralInput
            label={getTranslation('confirmPageCompanyEmail')}
            id={CompanyFormFields.EMAIL}
            onChange={handleErrorsCompanyForm}
            hasError={Boolean(errors[CompanyFormFields.EMAIL])}
            errorMessage={
              errors[CompanyFormFields.EMAIL]?.message === 'Invalid format'
                ? getTranslation('confirmPageErrorMessage')
                : getTranslation('confirmPageRequiredField')
            }
            validationPattern={emailNumValidationPattern}
            register={register}
            setValue={setValue}
            watch={watch}
            touchedFields={touchedFields}
          />

          <GeneralInput
            label={getTranslation('confirmPageZipCode')}
            id={CompanyFormFields.ZIP}
            onChange={handleErrorsCompanyForm}
            hasError={Boolean(errors[CompanyFormFields.ZIP])}
            errorMessage={
              errors[CompanyFormFields.ZIP]?.message === 'Invalid format'
                ? getTranslation('confirmPageErrorMessage')
                : getTranslation('confirmPageRequiredField')
            }
            validationPattern={zipValidationPattern}
            register={register}
            setValue={setValue}
            watch={watch}
            touchedFields={touchedFields}
          />
        </div>
      </form>
    </div>
  )
}

export default CompanyForm
