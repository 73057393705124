import { axios } from '@/configs'
import useSWRMutation from 'swr/mutation'
import { useCustomRouter } from '@/root/shared-hooks'

interface usePostAttributeProps {
  reservationId: number
  seqN: number
  value: string
  modificationMode: string
}

export function useEditPersonalData({ seqN, value, reservationId, modificationMode }: usePostAttributeProps) {
  const {
    query: { reservationToken },
    isReady,
    locale,
  } = useCustomRouter()

  const editPersonalData = async (body: usePostAttributeProps) => {
    const { data } = await axios.post(`edit-passenger-data`, body)
    return data
  }

  const { trigger: postPersonalData } = useSWRMutation(isReady && `/reservation/${reservationToken}/${locale}`, () =>
    editPersonalData({ reservationId, seqN, value, modificationMode })
  )
  return { postPersonalData }
}
