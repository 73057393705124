import { FC } from 'react'
import { Fleet, News, Page, Question, ResponseContent, Slug } from '@/root/shared-types'
import { NewsPage } from '@/root/modules/bluewave'
import { DefaultPage } from '@/root/modules/bluewave/ContentPage/components/DefaultPage/DefaultPage'
import { FleetPage } from '@/root/modules/bluewave/ContentPage/components/FleetPage/FleetPage'
import { FAQPage } from '@/root/modules/bluewave/ContentPage/components/FAQPage/FAQPage'

interface StaticPageProps {
  page: ResponseContent<Page>
  slug: Slug
}
export const StaticPage: FC<StaticPageProps> = ({ page, slug }) => {
  if (slug === 'news') {
    return <NewsPage news={page as News[]} />
  }
  if (slug === 'fleet') {
    return <FleetPage {...(page as Fleet)} />
  }
  if (slug === 'faq') {
    return <FAQPage questions={page as Question[]} />
  }
  return <DefaultPage {...(page as Page)} />
}
