import { FC } from 'react'
import cn from 'classnames'
import { PencilIcon } from '@/root/ui/shared/icons/PencilIcon'

interface EditableFieldProps {
  title: string
  value: string
  onModeToggle(): void
}

export const EditableField: FC<EditableFieldProps> = ({ title, value, onModeToggle }) => {
  const displayCol = value.length > 25
  return (
    <div className={cn({ 'flex-col': displayCol }, 'flex justify-between max-w-screen-xs ')}>
      <span className="text-neutral-medium-lighter flex min-w-[50%] gap-3 text-[16px]">
        <p>{title}</p>
      </span>
      <span className="text-neutral-medium-light flex min-w-[50%] flex-1 flex-row items-start justify-start gap-3 font-bold">
        <p className="max-w-[90%] overflow-hidden text-ellipsis">{value}</p>
        <span onClick={onModeToggle} className={cn({ 'self-end': displayCol }, 'cursor-pointer min-w-[19px]')}>
          <PencilIcon />
        </span>
      </span>
    </div>
  )
}
