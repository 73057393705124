import { FC } from 'react'
import { InputProps } from '@/root/shared-types'
import { useGetTranslations } from '@/root/shared-hooks'

interface SelectInputProps extends InputProps {
  options?: { text: string; value: string }[]
}
export const SelectInput: FC<SelectInputProps> = ({ id, control: { register }, options, onSave }) => {
  const { onChange, ...rest } = register(id)
  const { getTranslation } = useGetTranslations()
  return (
    <select
      className="h-9 w-[95%] rounded border border-neutral-normal-lighter px-2 md:max-w-screen-xs"
      {...rest}
      onChange={async (event) => {
        await onChange(event)
        onSave()
      }}
    >
      <option className="hidden">{getTranslation('selectPlaceholder')}</option>
      {options?.map(({ text, value }) => (
        <option value={value} key={text}>
          {text}
        </option>
      ))}
    </select>
  )
}
