import { GeneralInputProps } from '@/root/shared-types/companyInfoPage/company-info-types'
import React, { useEffect } from 'react'
import { FC } from 'react'
import { getLocalStorageValueFor, saveInLocalStorage } from './utils'

const GeneralInput: FC<GeneralInputProps> = ({
  label,
  id,
  type = 'text',
  placeholder,
  onChange,
  hasError = false,
  errorMessage,
  requiredErrorText = 'Required field',
  validationPattern,
  validationErrorText = 'Invalid format',
  register,
  setValue,
  watch,
  touchedFields,
}) => {
  const inputValue = watch(id)

  if (touchedFields && touchedFields[id] !== undefined) {
    saveInLocalStorage(id, inputValue ?? '')
  }

  useEffect(() => {
    const localStorageValue = getLocalStorageValueFor(id)
    setValue && setValue(id, localStorageValue ?? '')
  }, [id, setValue])

  return (
    <div className="relative mb-8 md:grid-cols-[1fr,2fr]">
      <div className="grid grid-cols-1 md:grid-cols-[1fr,2fr]">
        {label && (
          <div className="min-w-140 flex items-center">
            <label htmlFor={id} className="w-160 items-center">
              {label}
            </label>
          </div>
        )}

        <input
          id={id}
          className={`pr-161 border-neutral-normal-lighter content-center rounded border p-2 
          ${hasError ? 'border-alert-base bg-error-lightest border border-solid' : 'bg-bw-white border-gray-300'}`}
          type={type}
          placeholder={placeholder}
          {...register(id, {
            required: requiredErrorText,
            pattern: {
              value: validationPattern,
              message: validationErrorText,
            },
          })}
          onChange={onChange}
        />
      </div>
      {hasError && (
        <div className="top-15 text-error absolute right-0 text-center text-xs font-bold">{errorMessage}</div>
      )}
    </div>
  )
}

export default GeneralInput
